<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useRouter } from 'vue-router';
import { currencyList, PlayerWallet } from "@/plugins/ajax.js";

// vuex
const store = useStore();

// router
const router = useRouter();

const fp = computed(() => store.getters.fp);

const currencySelect = computed(() => store.getters.currencySelect);

const balance = ref('');

//取得 api data 並儲存
const currency = reactive({ arr: [] });

// 將 currencySelect 存入 model 變數
const currencySelectStr = ref(currencySelect.value);

// AJAX request modules
const region_block = (code = 0) => code === 105;

const wallet_request_params = computed(() => ({ account: store.getters.fp, currency: store.getters.currencySelect }));

// 監控幣別
watch(currencySelectStr, (newVal) => {
    store.dispatch('currencySelectAc', newVal);
    balance.value = "⏳";
    PlayerWallet(wallet_request_params.value).then(({ data, status }) => {
        if (Number(status.code) === 105) {
            router.push({ path: "/warning" });
            return;
        }
        balance.value = data.data.amount;
        localStorage.setItem('currency', newVal);
    });
});

// lifecircle
onMounted(() => {
    balance.value = "⏳";
    Promise.all([
        currencyList(),
        PlayerWallet(wallet_request_params.value),
    ]).then(([currency_data, wallet_data]) => {
        const should_region_block = region_block(currency_data.data.status.code) || region_block(wallet_data.data.status.code);
        if (should_region_block) {
            router.push({ path: "/warning" });
            return;
        }
        currency.arr = currency_data.data.data?.currencyKind ?? "";
        balance.value = wallet_data.data.data?.amount ?? "";
    });
});
</script>

<template>
    <div class="member">
        <router-link to="/" class="member-close">
            <img src="/img/svg/x.svg" alt="back to home" />
        </router-link>
        <div class="member-box">
            <div class="member-box-title">{{ $t('info') }}</div>
            <div class="member-box-item">
                <label>{{ $t('account') }} : </label>
                <p>{{ fp }}</p>
            </div>
            <div class="member-box-item">
                <label for="member-currency-selector">{{ $t('currency') }} : </label>
                <select id="member-currency-selector" v-model="currencySelectStr">
                    <option v-for="(item) in currency.arr" :key="item" :value="item">{{ item }}</option>
                </select>
                <span>{{ $t('balance') }} : {{ balance }}</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.member {
    @include flex(row, center, center);
    height: 100vh;
    width: 100%;

    &-close {
        position: fixed;
        right: $base-gap-1;
        top: $base-gap-1;
        cursor: pointer;

        img {
            width: 32px;
            height: 32px;
        }
    }

    &-box {
        background: $w-gradient-1;
        color: #fff;
        border-radius: $l-radius;
        padding: 0 $base-gap-2;
        width: 40%;

        &-title {
            text-align: center;
            font-size: 28px;
            padding: $base-gap-3 0 $base-gap-1 0;
            border-bottom: 1px solid $w-gradient-3;
        }

        &-item {
            @include flex(row, flex-start, center);
            padding: $base-gap-1 0;

            label {
                font-size: 24px;
            }

            p {
                font-size: 24px;
                margin-left: $base-gap-1;
            }

            select {
                font-size: 24px;
                min-width: 150px;
                margin-left: $base-gap-1;

                &:focus {
                    outline: none;
                }
            }

            span {
                font-size: 24px;
                margin-left: $base-gap-1;
            }
        }
    }
}

@include big {
    .member {
        &-box {
            width: 60%;

            &-item {
                @include flex(column, flex-start, center);

                label {
                    font-size: 20px;
                    margin-bottom: $base-gap-1;
                }

                p {
                    font-size: 16px;
                }

                select {
                    font-size: 24px;
                    margin-left: 0;
                    margin-bottom: $base-gap-1;
                }

                span {
                    font-size: 20px;
                }
            }
        }
    }
}

@include small {
    .member {
        &-box {
            width: 85%;
        }
    }
}
</style>
